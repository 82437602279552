<template>
    <div class="dark:bg-gray-800 bg-white relative">
        <div class="lg:container mx-auto dark:bg-gray-800 bg-white">
            <div class="flex dark:bg-gray-800 bg-white flex-col lg:flex-row">
                <div class=" sm:w-full xl:py-24 sm:pt-10 sm:pb-0 px-5 ">
                    <div class="mb-10 text-center  lg:text-left heading">
                        <h4 class="md:text-5xl text-black dark:text-gray-100 text-2xl mb-4">
                            Наши преимущества
                        </h4>
                        <p class="subheading  text-xl text-black dark:text-gray-100 text-center lg:text-left">
                            20 лет на рынке логистических услуг
                        </p>
                    </div>
                    <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 sm:w-full xl:w-1/2 gap-4 lg:pb-24 sm:pb-10">
                        <div class="cursor-pointer rounded-lg dark:hover:bg-gray-600 transition-all duration-1000 ease-out dark:bg-gray-800 bg-white hover:bg-gray-100
            sm:flex sm:justify-center md:justify-start sm:text-center md:text-left" v-for="(item, idx) in items"
                             :key="idx">
                            <div class="p-4">
                                <img class="mb-4 sm:mx-auto md:mx-0 h-16 icon" :src="item.image" alt="person">
                                <div class="text">
                                    <h5 class="counter">
                                        {{ item.counter }}
                                    </h5>
                                    <p class="dark:text-gray-100 text-gray-800">{{ item.text }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <router-link tag="div" to="/fff" class=" rounded-lg  dark:bg-gray-900 bg-gray-800 ">
                            <div class="sm:py-12 md:p-4 h-full">
                                <div
                                    class="cursor-pointer  h-full card__contact flex flex-col justify-center items-center ">
                                    <h5 class="contact-link">
                                        Связаться с <br>нами <span><img class="inline-flex" alt="Белтаможсервис"
                                                                        src="@/assets/img/icons/arrow.svg"
                                                                        data-alt="arrow"></span>
                                    </h5>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>

            </div>
        </div>
        <div class="p-24  hidden xl:block absolute top-0 right-0 w-1/2">
            <div class="image-wrapper  flex justify-end h-full max-h-144">
                <img v-show="dark" class=" lg:object-left xl:object-center "
                     src="@/assets/img/iso/bts-truck-green.svg" alt="Белтаможсервис">
                <img  v-show="!dark" class="lg:object-left xl:object-center " src="@/assets/img/iso/bts-deep-blue.svg"
                     alt="Белтаможсервис">

            </div>
        </div>
    </div>


</template>

<script>

export default {
    name: "IndexAdvantages",
    data: () => ({
        items: [
            {
                image: require('@/assets/img/icons/person.svg'),
                counter: '2400+',
                text: ' Сотрудников в штате',
            },
            {
                image: require('@/assets/img/icons/document.svg'),
                counter: '3000+',
                text: 'Деклараций в день',
            },
            {
                image: require('@/assets/img/icons/warehouse.svg'),
                counter: '96 га',
                text: 'Площадь инфраструктуры',
            },
            {
                image: require('@/assets/img/icons/container.svg'),
                counter: '10000т+',
                text: 'Обработка груза в день',
            },
            {
                image: require('@/assets/img/icons/truck.svg'),
                counter: '2000+',
                text: 'Обслуживание ТС в день',
            },
        ]
    }),
    computed:{
        dark(){
            return this.$store.getters['darkTheme']
        }
    }

}
</script>

<style scoped>

.main-color {
    color: #5CB7B1;
}

.counter {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 33px;
    color: #5CB7B1;
}

.dark .icon {
    filter: invert(1);
}

.heading {
    font-weight: bold;
}

.subheading {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.contact-link {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #5CB7B1;
}
</style>