<template>

  <div class="dark:bg-gray-800 bg-white">
    <div class="background dark:bg-gray-900 bg-gray-100  ">
      <div class="lg:container mx-auto">
        <div class="bg-logo  relative">
          <div class="xl:p-24 md:p-10 sm:p-3 ">
            <h1 class="font-bold text-center text-black dark:text-gray-300 xl:text-5xl text-2xl">Услуги РУП «Белтаможсервис»</h1>
            <h5 class="font-bold text-xl  text-black dark:text-gray-300 text-center pb-10 ">20 лет опыта работы в сфере логистической деятельности</h5>

            <div  v-show="slides.length" class="swiper-services overflow-hidden ">
              <swiper  v-if="slides.length" ref="swiperServices" :options="swiperOptions" class="swiper-wrapper">
                <swiper-slide class="  dark:bg-gray-800 bg-gray-50 dark:text-gray-300 text-gray-700 rounded-md " :key="slide.id" v-for="(slide, idx) in slides">
                  <div class="flex justify-center p-5 max-h-144 h-2/3">
                    <img :src="slide.img" class="min-h-52 h-full max-h-56" alt="Белтаможсервис">
                  </div>
                  <div class="px-5 flex flex-col items-start  py-6 h-full">
                    <h4 class="font-bold text-lg mb-5"><span class="text-teal-600">{{ idx + 1 }} </span>{{ slide.title }}</h4>
                    <a v-if="slide.link.indexOf('http') !== -1" :href="slide.link"  class="inline-flex">Подробнее <img class="ml-2" src="@/assets/img/icons/arrow.svg" alt=""></a>
                    <router-link v-else  :to="slide.link" tag="a" class="inline-flex">Подробнее <img class="ml-2" src="@/assets/img/icons/arrow.svg" alt=""></router-link>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="swiper-button-prev swiper-services-button-prev swiper-nav sm:hidden md:hidden lg:flex" :style="{ backgroundImage:'url('+require('@/assets/img/buttons/arrow-grey.svg')+')' }"></div>
              <div class="swiper-button-next swiper-services-button-next swiper-nav__reversed sm:hidden md:hidden lg:flex" :style="{ backgroundImage:'url('+require('@/assets/img/buttons/arrow-grey.svg')+')' }"></div>
              <div class="swiper-pagination"></div>
            </div>
            <div class="text-center sm:mb-40 md:mb-20 mt-10">
              <a class="bg-blue-300 py-4 px-12 rounded hover:bg-teal-300 font-medium  inline-block transition duration-500 transition-all" href="">
               Подробнее
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import  {Swiper as SCore ,Navigation} from 'swiper';
import 'swiper/swiper-bundle.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'
SCore.use(Navigation);
import axios from "axios";

export default {
  name: "SwiperServices",
  components:{
    Swiper,SwiperSlide
  },
  data: () => ({
    images:[
        {id:'5304',link: require('@/assets/img/iso/express2.svg')},
        {id:'2831',link: require('@/assets/img/iso/calender2.svg')},
        {id:'2830',link: require('@/assets/img/iso/forklift.svg')},
        {id:'2829',link: require('@/assets/img/iso/map2.svg')},
        {id:'2828',link: require('@/assets/img/iso/train-1.svg')},
        {id:'2827',link: require('@/assets/img/iso/truck-1.svg')},
        {id:'2826',link: require('@/assets/img/iso/belborder.svg')},
        {id:'2825',link: require('@/assets/img/iso/comp1.svg')},
        {id:'2824',link: require('@/assets/img/iso/china.svg')},
        {id:'2823',link: require('@/assets/img/iso/plane-1.svg')},
    ],
    slides: [],
    swiperOptions: {
      observer: true,
      observeParents: true,
      direction: 'horizontal',
      slidesPerView: 3,
      centeredSlides: true,
      speed: 400,
      loop: true,
      spaceBetween: 50,
      navigation: {
        nextEl: '.swiper-services-button-next',
        prevEl: '.swiper-services-button-prev',
      },
      breakpoints: {
        280: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 1,
        },
        990: {
          slidesPerView: 2,
        },
        1200:{
          slidesPerView: 3,
        }
      }
    }
  }),
  computed: {
    swiper() {
      return this.$refs.swiperServices
    }
  },
  created() {
    this.fetchNews()
  },
  mounted() {

  },
  methods:{
    fetchNews() {
        axios.get('https://declarant.by/rest/services/slider' )
            .then(res => {
                let slides = res.data.data
                this.slides = slides.map(s => {
                    let img = this.images.filter(i => i.id === s.id)[0]
                    s.img = img.link
                    return s
                })

            })
    }
  }
}
</script>

<style scoped>
.swiper-slide{
  height: auto!important;
}
.background{clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);}
img{
  max-height: 215px!important;
}

.swiper-nav, .swiper-nav__reversed{
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
}
.swiper-button-prev:after, .swiper-button-next:after{
  display: none;
}
.swiper-nav__reversed{
  transform: rotate(180deg);
}
@media (min-width: 280px) and (max-width: 990px){
  .swiper-button-prev, .swiper-button-next{
    display: none!important;
  }
}
</style>