<template>
    <div class="" id="contactUs">
        <div class="p-6 flex dark:bg-gray-800 bg-white text-white flex-col justify-center relative lg:pl-0">
            <h3 class="text-2xl dark:text-white text-gray-700 mb-3">
                {{ $t('forms.writeUs') }}
            </h3>
            <div class=" flex flex-col justify-center">
                <div class="flex flex-col">
                    <label for="name" class="hidden"> {{ $t('forms.name') }}</label>
                    <input v-model="form.name" type="text" name="name" id="name" :placeholder="$t('forms.name')"
                           class="w-100 mt-2 py-3 px-3 dark:bg-gray-800  bg-white   dark:text-white text-gray-700  focus:-indigo-500 focus:outline-none border-b dark:border-white border-gray-700">
                    <small class="text-red-600"
                           v-if="!$v.form.name.required && $v.form.name.$dirty">{{
                            $t('forms.validation.required')
                        }}</small>
                </div>

                <div class="flex flex-col mt-2">
                    <label for="email" class="hidden">Email</label>
                    <input v-model="form.email" type="email" name="email" id="email" placeholder="Email"
                           class="w-100 mt-2 py-3 px-3 dark:bg-gray-800  bg-white  dark:text-white text-gray-700  focus:-indigo-500 focus:outline-none
border-b dark:border-white border-gray-700">
                    <small class="text-red-600"
                           v-if="!$v.form.email.required && $v.form.email.$dirty">{{
                            $t('forms.validation.required')
                        }}</small>
                    <small class="text-red-600"
                           v-if="!$v.form.email.email && $v.form.email.$dirty">{{
                            $t('forms.validation.email')
                        }}</small>
                </div>

                <div class="flex flex-col mt-2">
                    <label for="tel" class="hidden">{{ $t('forms.tel') }}</label>
                    <input v-model="form.tel" type="tel" name="tel" id="tel" :placeholder="$t('forms.tel')"
                           class="w-100 mt-2 py-3 px-3 dark:bg-gray-800  bg-white  dark:text-white text-gray-700  focus:-indigo-500 focus:outline-none border-b dark:border-white border-gray-700">
                    <small class="text-red-600"
                           v-if="!$v.form.tel.required && $v.form.tel.$dirty">{{
                            $t('forms.validation.required')
                        }} </small>
                </div>
                <div class="flex flex-col mt-2">
                    <label for="organization" class="hidden">{{ $t('forms.organization') }}</label>
                    <input v-model="form.organization" type="text" name="organization" id="organization"
                           :placeholder="$t('forms.organization')"
                           class="w-100 mt-2 py-3 px-3 dark:bg-gray-800  bg-white  border-b dark:border-white border-gray-700    dark:text-white text-gray-700focus:-indigo-500 focus:outline-none">
                </div>
                <div class="flex flex-col mt-2">


                    <select v-model="form.place" id="place"
                            class="w-100 mt-2 py-3 px-3 dark:bg-gray-800  bg-white border-b dark:border-white border-gray-700   dark:text-white text-gray-700 focus:-indigo-500 focus:outline-none">
                        <option class="my-1 box-border" value="" disabled>{{ $t('forms.location') }}</option>
                        <option value="38">Брест</option>
                        <option value="39">Витебск</option>
                        <option value="40">Гомель</option>
                        <option value="41">Гродно</option>
                        <option value="42">Минск</option>
                        <option value="43">Могилев</option>

                    </select>
                </div>
                <div class="flex flex-col mt-2">
                    <label class="hidden">Number</label>
                    <textarea v-model="form.message" id="message" :placeholder="$t('forms.message')"
                              class="w-100 mt-2 py-3 px-3 dark:bg-gray-800  bg-white border-b dark:border-white border-gray-700  dark:text-white text-gray-700  focus:-indigo-500 focus:outline-none"/>
                    <small class="text-red-600"
                           v-if="!$v.form.message.required && $v.form.message.$dirty">{{
                            $t('forms.validation.required')
                        }}</small>
                </div>

                <label class="inline-flex items-center pt-2 my-2">
                    <input type="checkbox" v-model="form.agree"
                           class="form-checkbox"/>
                    <span class="ml-2 dark:text-white text-gray-700">{{ $t('forms.agree') }}</span>
                </label>
            </div>

            <button @click="submit" :disabled="!form.agree"
                    class="md:w-40 bg-green-400 hover:bg-blue-dark text-white  py-3 px-6  mt-3 hover:bg-blue-400 rounded
              transition ease-in-out duration-300">
                {{ $t('forms.send') }}
            </button>

            <!--MODAL-->
            <div v-show="showModal" :class="{'bg-teal-600':result === true,'bg-red-600':result === false }"
                 class="absolute shadow-2xl flex flex-col rounded justify-around  top-1/3 left-1/2 transform
           -translate-x-1/2 -translate-x-1/2 w-80">
                <img class="p-5 h-32" v-if="result === true" src="@/assets/img/icons/success.svg" alt="Белтаможсервис">
                <img class="p-5 h-32" v-if="result === false" src="@/assets/img/icons/error.svg" alt="Белтаможсервис">

                <p class="text-center pb-3">{{ message }}</p>

                <div class="close" @click="close"></div>
            </div>
        </div>

    </div>


</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'
import axios from "axios";

export default {
    name: "WriteUsMessage",
    mixins: [validationMixin],
    data: () => ({
        result: false,
        showModal: false,
        message: '',
        form: {
            name: '',
            organization: '',
            email: '',
            tel: '',
            place: '',
            message: '',
            agree: false
        },
        errors: []
    }),
    validations: {
        form: {
            name: {
                required,
            },
            organization: {},
            email: {
                required,
                email
            },
            tel: {
                required,
            },
            message: {
                required,
            },
            agree: {
                required,
            }
        },

    },
    created() {
        document.addEventListener('keyup', this.escape)
    },
    destroyed() {
        document.removeEventListener('keyup', this.escape)
    },
    methods: {
        submit() {
            this.$v.$touch()
            if (!this.$v.$invalid) {

                axios.post('https://declarant.by/rest/forms/main', this.form)
                    .then(res => {
                        this.$notify({
                            title: 'Успешно',
                            text: res.data.data,
                            type: 'success'
                        })
                        this.$v.$reset()
                    }).catch(err => {
                    this.$notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })

            }
        },
        escape() {
            if (event.keyCode === 27) {
                this.close()
            }
        },
        close() {
            this.showModal = false
            this.result = null
            this.message = ''
        }
    },

}
</script>

<style scoped>
.close {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
}

.close:hover {
    opacity: 1;
}

.close:before, .close:after {
    position: absolute;
    left: 5px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
}

.close:before {
    transform: rotate(45deg);
}

.close:after {
    transform: rotate(-45deg);
}
select{
    -webkit-appearance: none;
}

</style>