<template>
    <div class="dark:bg-gray-800 bg-white  relative">
        <yandex-map class="map w-full" id="ymap_contacts_main_page" :coords="[53.88, 27.56]" :settings="settings"
                    :zoom="zoom" :controls="controls" :use-object-manager="true"
                    :object-manager-clusterize="true"
                    :cluster-options="clusterOptions"
        >
            <ymap-marker v-for="(feature, idx) in features" :key="idx"
                         :marker-id="feature.id"
                         marker-type="placemark"
                         :coords="feature.geometry.coordinates"
                         :hint-content="feature.properties.hintContent"
                         :balloon="{body: feature.properties.balloonContent}"
                         :properties="feature.properties"
                         :options="feature.options"
                         :cluster-name="'ТЭД'"
            >
            </ymap-marker>
        </yandex-map>
        <div
            class="dark:bg-gray-900 lg:shadow-2xl bg-white text-black dark:text-gray-100  lg:absolute -bottom-10 right-20 xl:p-8 sm:px-5 sm:py-10 lg:py-5 lg:rounded">
            <div class="flex sm:flex-col lg:flex-row">
                <div class=" ">
                    <h3 class="text-2xl font-bold mb-3">
                        Контакты
                    </h3>
                    <div class="max-w-md mb-5">
                        <div class="flex items-center dark:text-white mb-3">
                            <img class="mr-6 icon" src="@/assets/img/icons/location.svg" alt="">
                            <p class="font-medium text-lg">220036, г. Минск ул.<br>
                                Лермонтова, 27, каб. 502 </p>
                        </div>
                        <div class="flex  items-center mb-3">
                            <img class="mr-6 icon" src="@/assets/img/icons/phone.svg" alt="">
                            <p class="font-medium text-lg">197 (по Республике Беларусь)<br>
                                +375 17 275 57 88 (из-за пределов<br>
                                Республики Беларусь)</p>
                        </div>
                        <div class="flex items-center mb-3">
                            <img class="mr-6 icon" src="@/assets/img/icons/envelope.svg" alt="">
                            <p class="font-medium text-lg">office@btslogistics.by</p>
                        </div>
                    </div>
                    <a href="#contactUs"
                       class="btn mb-5 py-2 text-black text-center block sm:w-full md:w-1/2 lg:w-full  bg-blue-300 hover:bg-teal-300 font-medium rounded  transition duration-500 transition-all ">
                        Отправить сообщение
                    </a>
                    <div class="flex mb-3">
                        <a class="mr-5" :key="'social'+idx" :href="link.to" v-for="(link, idx) in social"
                           target="_blank" rel="nofollow">
                            <img :style="{'width':'25px','height':'25px'}"
                                 :src="require('@/assets/img/social/'+link.img)" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>


    </div>


</template>

<script>

import {ymapMarker, yandexMap} from 'vue-yandex-maps'
import axios from "axios";

export default {
    name: "Contacts2",
    data: () => ({
        ymaps: {},
        settings: {
            apiKey: '5dc084cc-21f7-495e-989c-2518999eca51',
            lang: 'ru_RU',
            coordorder: 'latlong',
            enterprise: false,
            version: '2.1',
        },
        clusterOptions: {},
        zoom: 5,
        controls: ['zoomControl'],
        social: [
            {
                to: 'https://www.linkedin.com/company/%D1%80%D1%83%D0%BF-%D0%B1%D0%B5%D0%BB%D1%82%D0%B0%D0%BC%D0%BE%D0%B6%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%8120%D0%BB%D0%B5%D1%82/',
                img: 'linkedin.svg'
            },
            {
                to: 'https://www.instagram.com/beltamozhservice/',
                img: 'inst.svg'
            },
            {
                to: 'https://www.facebook.com/btslogistics/',
                img: 'facebook.svg'
            },
            {
                to: '',
                img: 'twitter.svg'
            },
            {
                to: '',
                img: 'vk.svg'
            },
            {
                to: 'https://www.youtube.com/channel/UC1_RJPcShwneP6ytpiIi5fQ',
                img: 'youtube.svg'
            }
        ],

        features: []
    }),
    created() {
        this.fetch()
    },

    methods: {
        fetch() {
            axios.get('https://declarant.by/rest/maps/main')
                .then(res => {
                    this.features = res.data.data.features
                    let obj = {}
                    this.features.forEach(function (el) {
                        obj[el.properties.clusterCaption] = {
                            clusterIconLayout: 'default#pieChart',
                        }
                    })
                    this.clusterOptions = obj
                })
        }
    },
    components: {yandexMap, ymapMarker}
}
</script>

<style scoped>

.dark .icon {
    filter: invert(1);
}

.dark #ymap_contacts_main_page >>> .ymaps-2-1-79-ground-pane {
    filter: grayscale(100%) invert(1);
    -webkit-filter: grayscale(100%) invert(1);

}

#ymap_contacts_main_page >>> .ymaps-2-1-79-map-copyrights-promo {
    display: none;
}

#ymap_contacts_main_page >>> .ymaps-2-1-79-copyright__layout {
    opacity: .2;
}

.map {
    height: 550px;
}
</style>