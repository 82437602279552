<template>
    <div>
        <IndexTop></IndexTop>
        <IndexSecondBlock></IndexSecondBlock>
        <SwiperServices></SwiperServices>

        <IndexAdvantages></IndexAdvantages>
        <IndexSoftware></IndexSoftware>

        <SwiperNews></SwiperNews>
        <ContactsWideMap ></ContactsWideMap>


        <div class="dark:bg-gray-800 bg-white py-20">
            <div class="flex lg:container mx-auto ">
                <div class="sm:w-full md:w-1/2 xl:w-1/3">
                    <WriteUsMessage class="mx-2"></WriteUsMessage>
                </div>
                <div class="sm:hidden md:w-1/2 xl:w-2/3 md:block dark:bg-gray-800 bg-white">
                    <div class="flex justify-end content-center items-center h-full">
                        <img loading="lazy" src="@/assets/img/iso/bts-cargo_1.svg"  class="h-auto max-h-144" alt="">
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import IndexSecondBlock from '@/components/IndexSecondBlock.vue'
import IndexTop from "@/components/IndexTop";
import IndexAdvantages from "../components/IndexAdvantages";

import IndexSoftware from '../components/IndexSoftware';
import ContactsWideMap from "../components/ContactsWideMap";
import WriteUsMessage from "../components/forms/WriteUsMessage";
import SwiperNews from '../components/sliders/SwiperNews';
import SwiperServices from '../components/sliders/SwiperServices'

export default {
    meta: {
        title:'РУП Белтаможсервис'
    },
    name: 'Index',
    components: {
        IndexSecondBlock,
        IndexTop,
        SwiperServices,
        IndexAdvantages,
        IndexSoftware,

        WriteUsMessage,
        SwiperNews,ContactsWideMap
    }
}
</script>
