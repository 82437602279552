<template>
  <div class="dark:bg-gray-900 bg-white ">
    <div class="lg:container mx-auto ">
      <div class="dark:bg-gray-900 text-black dark:text-gray-100 bg-white text-center relative pb-5">
        <div class="xl:p-24 md:p-10 sm:p-3">
          <div class="flex lg:flex-row sm:flex-col md:justify-between mb-16">
            <h1 class="news-header dark:text-gray-100 text-black md:text-4xl sm:mt-5 md:mt-0 sm:text-2xl sm:mb-5 md:mb-0 lg:text-left sm:text-center sm:w-full lg:w-1/4">
              Новости и статьи</h1>
            <h5 class="news-subheader lg:text-xl sm:text-lg lg:text-left md:text-center  sm:w-full max-w-4xl lg:w-3/4">
              РУП «Белтаможсервис» всегда стремится вперед, открывая для себя
              новые перспективы развития. Обеспечиваем перевозку грузов всеми видами транспорта: автомобильным,
              железнодорожным, морским и авиатранспортом.</h5>
          </div>
          <div v-show="slides.length" class="swiper-news overflow-hidden">
            <swiper v-if="slides.length"  ref="swiperNews" :options="swiperOptions" class="swiper-wrapper">
              <swiper-slide class=" bg-white dark:bg-gray-800 h-full rounded-md" :key="slide.id"
                   v-for="slide in slides">

                <div class="flex image-wrapper justify-end">
                  <img loading="lazy" class="h-full rounded-t-md w-full object-center object-cover " :src="slide.image" alt="Белтаможсервис">
                </div>
                <div class="my-6 p-3 h-full">
                  <h4 class="px-1 font-bold text-left text-lg mb-10">{{ slide.title }}</h4>
                  <div class="flex">
                    <router-link :to="{name:'NewsDetail', params:{code:slide.code}}" tag="a"
                                 class="inline-flex px-1 detail-link">Подробнее
                      <img class="ml-2" src="@/assets/img/icons/arrow.svg" alt="Белтаможсервис">
                    </router-link>
                  </div>

                </div>

              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev swiper-nav swiper-news-button-prev sm:hidden md:hidden lg:flex"
                 :style="{ backgroundImage:'url('+require('@/assets/img/buttons/arrow-grey.svg')+')' }"></div>
            <div class="swiper-button-next swiper-nav__reversed swiper-news-button-next sm:hidden  md:hidden lg:flex "
                 :style="{ backgroundImage:'url('+require('@/assets/img/buttons/arrow-grey.svg')+')' }"></div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import  {Swiper as SCore ,Navigation} from 'swiper';
SCore.use(Navigation);
import 'swiper/swiper-bundle.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import axios from "axios";

export default {
  name: "SwiperNews",
  components:{
    Swiper,SwiperSlide
  },
  data:()=> ({
    slides:[],
    swiperOptions: {
      observer: true,
      observeParents: true,
      direction: 'horizontal',
      slidesPerView: 3,
      centeredSlides: true,
      speed: 400,
      loop:true,
      spaceBetween: 50,
      navigation: {
        nextEl: '.swiper-news-button-next',
        prevEl: '.swiper-news-button-prev',
      },
      breakpoints: {
        280: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 1,
        },
        990: {
          slidesPerView: 2,
        },
        1200:{
          slidesPerView: 3,
        }
      }
    }

  }),
  computed: {
    swiper() {
      return this.$refs.swiperNews
    }
  },
  created() {
    this.fetchNews()
  },
  methods:{
    fetchNews() {
      let payload = {
        page: 1,
        perPage: 15,
        newsCategory: ''
      }

      axios.post('https://declarant.by/rest/news', payload)
        .then(res => {
            this.slides = [...res.data.data.items]
        })


    }
  }

}
</script>

<style scoped>
.detail-link:hover img {
  transition: all;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  transform: translateX(10px);
}

.swiper-slide {
  height: auto !important;
}

.card {
  max-width: 393px;
  height: auto;
}

.image-wrapper {
  height: 245px;
}

.news-header {
  font-style: normal;
  font-weight: bold;
}

.news-subheader {
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;

}

.swiper-nav, .swiper-nav__reversed {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
}

.swiper-button-prev:after, .swiper-button-next:after {
  display: none;
}

.swiper-nav__reversed {
  transform: rotate(180deg);
}

@media (min-width: 280px) and (max-width: 990px) {
  .swiper-button-prev, .swiper-button-next {
    display: none !important;
  }
}
</style>