<template>
    <div class="dark:bg-gray-800 bg-white">
        <div class="sm:pt-44 lg:pt-64 sm:pb-24 lg:pb-48  dark:bg-black bg-white text-black dark:text-white background">

            <div class="lg:container mx-auto flex lg:flex-row sm:flex-col px-5 ">
                <div class="lg:w-1/2 sm:w-full  h-full sm:mb-10 lg:mb-0 ">
                    <div class=" flex justify-center items-center content-center">
                        <img src="@/assets/img/iso/cubes-blue.svg" class="h-full" alt="">
                    </div>
                </div>
                <div class="lg:w-1/2 sm:w-full h-full  ">
                    <div class="relative">
                        <div class="absolute top-1/2 right-0  lg:-translate-x-0 transform -translate-y-1/2 ">
                            <router-link tag="a" to="/software" class="block" >
                                <h3 class="btn   text-center px-8 py-3 font-semibold inline-block text-white text-xl"><span class="inline-block">Программные продукты</span></h3>
                            </router-link>

                        </div>
                    </div>
                    <ul class=" lg:text-right sm:text-left mt-10 mb-5 sm:pt-10 lg:pt-0 ">
                        <li v-for="(i, idx) in text" :key="'text'+idx" class=" text-lg leading-7"><span class="bangers text-3xl pr-2 ">{{idx +1 }}</span> {{i}}
                        </li>

                    </ul>

                    <div class="overflow-hidden  font-bangers mt-10 mx-5">
                        <swiper v-if="slides.length" ref="swiperSoft" :options="swiperOptions" class="h-full swiper-wrapper">
                            <swiper-slide v-for="(slide,idx) in slides" :key="'prog-'+idx" class="w-full">
                                <router-link tag="a" :to="'/software/products/'+slide.link" class="block h-full bangers lg:whitespace-nowrap text-6xl">
                                    {{ slide.name }}
                                </router-link>
                            </swiper-slide>
                        </swiper>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Vue from 'vue'
import {Swiper as SwiperClass, Autoplay} from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

SwiperClass.use([Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass)
import 'swiper/swiper-bundle.css'

export default {
    name: "IndexSoftware",
    components: {
        Swiper, SwiperSlide
    },
    data: () => ({
        text: [
            'Предоставляют возможность для формирования электронных таможенных документов, подписания их ЭЦП и представления таможенным органам.',
            'Упрощают формированию отчетов, предоставляемых резидентами свободных экономических зон в таможенные органы Республики Беларусь.',
            'Способствуют  деятельности таможенного агента при оказании услуг.',
            'Автоматизируют деятельности складов временного хранения, таможенных складов в части ведения учета и формирования отчётности для таможенных органов в соответствии с требованиями ГТК.',
            'Направлены на упрощенное формирование отчетности таможенными перевозчиками.',

        ],
        slides: [
            {name: 'E-DECLARANT', link: 'e-declarant',},
            {name: 'ARM "PTO"', link: 'pto',},
            {name: 'AS "SEZ"', link: 'sez',},
            {name: 'ARM "BTS-SPECIALIST"', link: 'bts-specialist',},
            {name: 'PM "CUSTOMS CARRIER"', link: 'customs-carrier',},
        ],
        swiperOptions: {
            autoplay: {
                delay: 0,
            },
            observer: false,
            observeParents: false,
            direction: 'horizontal',
            slidesPerView: 'auto',
            spaceBetween: 50,
            speed: 5000,
            loop: true,
        }
    }),
    computed: {
        swiper() {
            return this.$refs.swiperSoft
        }
    },

}
</script>

<style scoped>
.bangers {
    font-family: 'Bangers', cursive !important;
}
.background{clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%)}

.btn {
    transform: skew(-21deg);
    background-color: #767ee3;
}

.btn > span {

    transform: skew(21deg);
}

body {
    font-family: Helvetica, Arial, sans-serif;
}


</style>