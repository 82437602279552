<template>
    <div class="container">
        <div class="playBut" ref="play">
            <svg version="1.1"
                 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                 x="0px" y="0px" width="100" height="213.7px" viewBox="0 0 213.7 213.7"
                 enable-background="new 0 0 213.7 213.7"
                 xml:space="preserve">
                <polygon ref="triangle" class="triangle" id="XMLID_18_" fill="none" stroke-width="7" stroke-linecap="round"
                         stroke-linejoin="round"
                         stroke-miterlimit="10" points="73.5,62.5 148.5,105.8 73.5,149.1 "/>
                </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: "PlayButtonMain",
    props:{
        darkTheme:{
            type:Boolean
        }
    },
    mounted() {
        let triangle = this.$refs.triangle
        if(this.darkTheme){
            triangle.style.stroke = '#6EE7B7'
        }else {
            triangle.style.stroke ='#000'
        }
    },
    watch:{
        darkTheme(){
            let triangle = this.$refs.triangle
            if(this.darkTheme){
                triangle.style.stroke = '#6EE7B7'
            }else {
                triangle.style.stroke ='#000'
            }
        }
    }
}
</script>

<style scoped>
.container {
    width: 100%;
    text-align: center;
}

.playBut {
    /* border: 1px solid red;
    */
    display: inline-block;
    -webkit-transition: all 0.5s ease;
    width: 50%;
    height: auto;
}

.playBut .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;

    transform: translateY(0) translateX(10px);
}

.playBut:hover .triangle {
    stroke-dashoffset: 0;
    opacity: 1;
}


</style>