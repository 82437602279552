<template>

    <div class="h-screen relative bg-white dark:bg-gray-900">
        <div id="particles-js" class="dark:bg-gray-800 bg-white background"></div>
        <div class="absolute sm:w-full lg:w-1/2 top-0 left-0 h-full flex items-center lg:container">
            <div class="bg-transparent sm:p-4 md:px-12 xl:p-36 relative">

                <h1 class="dark:text-gray-50 text-black sm:text-5xl md:text-6xl mb-10  leading-8 max-w-2xl tracking-tight font-extrabold">
                    {{ $t('index.title') }}
                </h1>
                <p class="text-base dark:text-gray-200 text-black md:text-xl sm:text-xl mb-16 lg:max-w-xl">
                    {{ $t('index.subtitle') }}
                </p>
                <a class="bg-blue-300 py-4 px-12 rounded hover:bg-teal-300 font-medium  inline-block transition duration-500 transition-all"
                   href="#contactUs">
                    {{ $t('contactUs') }}
                </a>
                <div class="absolute lg:bottom-20 md:bottom-5 sm:-bottom-10">
                    <div class="flex">
                        <a href="https://www.linkedin.com/company/%D1%80%D1%83%D0%BF-%D0%B1%D0%B5%D0%BB%D1%82%D0%B0%D0%BC%D0%BE%D0%B6%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%8120%D0%BB%D0%B5%D1%82/"
                           class="mr-5"><img src="@/assets/img/social/linkedin.png" alt="linkedin"></a>
                        <a href="https://www.instagram.com/beltamozhservice/" class="mr-5"><img
                            src="@/assets/img/social/inst.png" alt="inst"></a>
                        <a href="https://www.facebook.com/btslogistics/" class="mr-5"><img
                            src="@/assets/img/social/facebook.png" alt="facebook"></a>
                        <a href="https://www.youtube.com/channel/UC1_RJPcShwneP6ytpiIi5fQ" class="mr-5"><img
                            src="@/assets/img/social/yt.png" alt="yt"></a>
                    </div>
                </div>
            </div>

        </div>
        <div class="absolute sm:hidden md:block bottom-44 lg:right-32 md:right-10">
            <a href="https://www.youtube.com/watch?v=3ntRbn8DBCk&ab_channel=Beltamozhservice"
               class="text-white flex items-center play" target="_blank">
                <div class="relative mx-10">
                    <PlayButton :darkTheme="darkTheme" ></PlayButton>
                </div>
                <span class="text-lg uppercase dark:text-white text-black" >
                       Имиджевый <br> фильм 2021 <br><span class="font-normal">10:06</span>
                  </span>

            </a>
        </div>
        <UseCookie class="fixed bottom-0 z-50"></UseCookie>
    </div>


</template>

<script>

import 'particles.js';
import UseCookie from '../components/common/UseCookie'
import PlayButton from '../components/common/PlayButtonMain'

export default {
    name: "IndexTop",
    data:()=>({
       active:false
    }),
    components: {
        UseCookie, PlayButton
    },
    computed: {
        darkTheme() {
            return this.$store.getters['darkTheme']
        }
    },
    watch: {
        darkTheme() {
            this.init()
        }
    },
    mounted() {
        this.init()

    },
    methods: {
        init() {
            let color = "#ffffff"
            if (this.darkTheme === false) {
                color = "#000"
            }
            window.particlesJS('particles-js', {
                "particles": {
                    "number": {"value": 80, "density": {"enable": true, "value_area": 800}},
                    "color": {"value": color},
                    "shape": {
                        "type": "circle",
                        "stroke": {"width": 0, "color": "#000000"},
                        "polygon": {"nb_sides": 5},
                        "image": {"src": "img/github.svg", "width": 100, "height": 100}
                    },
                    "opacity": {
                        "value": 0.5,
                        "random": false,
                        "anim": {"enable": false, "speed": 1, "opacity_min": 0.1, "sync": false}
                    },
                    "size": {
                        "value": 3,
                        "random": true,
                        "anim": {"enable": false, "speed": 40, "size_min": 0.1, "sync": false}
                    },
                    "line_linked": {"enable": true, "distance": 150, "color": color, "opacity": 0.4, "width": 1},
                    "move": {
                        "enable": true,
                        "speed": 6,
                        "direction": "none",
                        "random": false,
                        "straight": false,
                        "out_mode": "out",
                        "bounce": false,
                        "attract": {"enable": false, "rotateX": 600, "rotateY": 1200}
                    }
                },
                "interactivity": {
                    "detect_on": "canvas",
                    "events": {
                        "onhover": {"enable": true, "mode": "repulse"},
                        "onclick": {"enable": true, "mode": "push"},
                        "resize": true
                    },
                    "modes": {
                        "grab": {"distance": 400, "line_linked": {"opacity": 1}},
                        "bubble": {"distance": 400, "size": 40, "duration": 2, "opacity": 8, "speed": 3},
                        "repulse": {"distance": 200, "duration": 0.4},
                        "push": {"particles_nb": 4},
                        "remove": {"particles_nb": 2}
                    }
                },
                "retina_detect": true
            },);
        },

    }

}
</script>

<style scoped>

.background {
    clip-path: polygon(0 0, 100% 0, 100% 80vh, 0 100%);
}

canvas {
    display: block;
    vertical-align: bottom;
}

@media screen and (max-width: 300px) {
    h1 {
        font-size: 2rem;
    }
}

/* ---- particles.js container ---- */
#particles-js {

    width: 100%;
    height: 100%;
    background-image: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.play div img.spin {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;

}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
