<template>
    <div>
        <div class="dark:bg-gray-900 bg-white sm:px-5 xl:px-5 py-20 ">
            <div class="lg:container mx-auto">
                <div
                    class="flex items-center sm:flex-col lg:flex-row h-full w-full dark:bg-gray-900 bg-white dark:text-white text-black bg-contain py-10 bg-left bg-no-repeat sm:px-5 lg:px-0 "
                    :style="{ backgroundImage:'url('+require('@/assets/img/logo/logo3d.svg')+')' }">

                    <div class="h-full sm:w-full sm:mb-10 md:mb-20 lg:mb-0 lg:w-1/2 ">
                        <div class=" font-semibold ">
                            <h2 ref="textBlock" class=" element xl:text-6xl lg:text-5xl md:text-4xl mb-9 sm:text-3xl">
                                РУП «Белтаможсервис»<br>
                                Логистический оператор <span class="dark:text-gray-500 text-black">№1</span>
                            </h2>
                            <p class="sm:text-xl md:text-lg sm:mb-8 md:mb-0">
                                Обеспечиваем перевозку грузов всеми видами транспорта: автомобильным, железнодорожным,
                                морским и авиатранспортом.
                            </p>
                        </div>
                    </div>

                    <div class="sm:w-full lg:w-1/2 h-full max-h-144 flex justify-center items-center">
                        <img v-show="dark" src="@/assets/img/iso/main-dark.svg" class="" alt="">
                        <img v-show="!dark" src="@/assets/img/iso/main.svg" class="" alt="">
                    </div>

                </div>
            </div>
        </div>
    </div>


</template>

<script>

export default {
    name: "IndexSecondBlock",
    computed: {
        dark() {
            return this.$store.getters['darkTheme']
        }
    },
}
</script>

<style scoped>

</style>
