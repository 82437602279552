<template>
    <div v-if="!cookie" class="dark:bg-blue-300 bg-black text-white dark:text-black  w-full">
        <div class="flex sm:flex-col md:flex-row xl:container mx-auto p-5">
            <p class="block sm:w-full md:w-3/4 lg:w-10/12">Мы используем cookie. Это позволит нам анализировать
                взаимодейтсвие посетителей с сайтом и делать его лучше.
                Продолжая пользоваться сайтом, вы соглашаетесь с использованием файлов cookie.</p>
            <button @click="setCookie"
                class="dark:bg-black bg-white rounded px-3 py-2 sm:w-full md:w-1/4 lg:w-2/12 dark:text-white text-black transform hover:-translate-y-1 transition transition-all ease-in">
                Окей
            </button>
        </div>

    </div>
</template>

<script>
export default {
    name: "UseCookie",
    data: () => ({
        cookie: false
    }),
    created() {
        this.cookie = this.getCookie('useCookies')
    },
    methods: {
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        },
        setCookie() {
            let date = new Date(Date.now() + 5184000e3);
            date = date.toUTCString();
            document.cookie = 'useCookies=true; path=/; expires='+date
            this.cookie = true
        }
    }
}
</script>

<style scoped>

.dark button:hover {
    -webkit-box-shadow: 0px 17px 15px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 17px 15px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 17px 15px 2px rgba(0, 0, 0, 0.2);
}

</style>